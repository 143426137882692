import React from "react";
import "../../assets/styles/customStyles.css";
import vector1 from "../../assets/vector-1.svg";
import AirlineGlobe from "./AirlineGlobe";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 786);
  
  if (!isMobile) {
    return (
      <div className="min-h-screen bg-black flex justify-center items-center w-full">
        <div className="bg-black overflow-hidden" style={{ width: '100vw', height: '100vh' }}>
          <div style={{ position: 'absolute', width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <img
              style={{ width: '100vw', height: '100vh', objectFit: 'cover' }}
              alt="Vector"
              src={vector1}
            />
            <div className="absolute" style={{
              width: '34vw', height: '34vw', top: '80vh', left: '-15vw',
              borderRadius: '50%', backdropFilter: 'blur(8vw)',
              background: 'radial-gradient(closest-side, rgb(120,79,166) 0%, transparent 100%)',
              opacity: '0.5'
            }} />
            <div style={{
              position: 'absolute', width: '30vw', height: '18vw', top: '20vh', left: '10vw'
            }}>
              <div className="text-container absolute top-0 left-0 font-[Lexend] font-semibold text-[6.25vw] leading-none text-[#865bb5] tracking-wide">
                Mercator
              </div>
              <div className="text-container absolute top-[7.75vw] left-0 font-[Lexend] font-light text-[2vw] leading-tight text-white opacity-50">
                mapping the unseen.
              </div>
              <div className="absolute top-[18vw] left-0 w-[23vw] h-[7.5vw]">
                <div className="relative w-full h-full">
                  <div className="absolute w-full h-full rounded-xl bg-white opacity-10"></div>
                  <Link to="/products">
                  <button className="button-container absolute inset-0 rounded-xl bg-transparent text-white text-[2.5vw] font-[Lexend] leading-tight flex justify-center items-center transition duration-200 ease-in-out hover:bg-[#332d2d]"> 
                  
                    let’s do this.
                  
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-screen" >
            <AirlineGlobe />
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="min-h-screen bg-black flex justify-center items-center w-full">
        <div className="bg-black overflow-hidden" style={{ width: '100vw', height: '100vh' }}>
          <div style={{ position: 'absolute', width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <img
              style={{ width: '100vw', height: '100vh', objectFit: 'cover' }}
              alt="Vector"
              src={vector1}
            />
            <div className="absolute" style={{
              width: '34vw', height: '34vw', top: '80vh', left: '-15vw',
              borderRadius: '50%', backdropFilter: 'blur(8vw)',
              background: 'radial-gradient(closest-side, rgb(120,79,166) 0%, transparent 100%)',
              opacity: '0.5'
            }} />
            <div style={{
              position: 'absolute', width: '60vw', height: '80vw', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center'
            }}>
              <div className="text-container font-[Lexend] font-semibold text-[12vw] text-[#865bb5] tracking-wide">
                Mercator
              </div>
              <div className="text-container font-[Lexend] font-light text-[7vw] leading-tight text-white opacity-50">
                mapping the unseen.
              </div>
              <div className="absolute top-[42vw] left-[12.5vw] w-[34.5vw] h-[12vw] flex flex-col items-center">
                <div className="relative w-full h-full">
                  <div className="absolute w-full h-full rounded-xl bg-white opacity-10"></div>
                  <Link to="/products">
                  <button className="button-container absolute inset-0 rounded-xl bg-transparent text-white text-[4.25vw] font-[Lexend] leading-tight flex justify-center items-center">
                    
                    let’s do this.
                    
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div>
    );
  }
};

export default LandingPage;
