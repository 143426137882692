import React from 'react';
import Globe from 'react-globe.gl';
import * as d3 from 'd3';
import indexBy from 'index-array-by';
import "../../assets/styles/customStyles.css";

const COUNTRY = 'United States';
const OPACITY = 0.22;

const airportParse = ([airportId, name, city, country, iata, icao, lat, lng, alt, timezone, dst, tz, type, source]) => ({ airportId, name, city, country, iata, icao, lat, lng, alt, timezone, dst, tz, type, source });
const routeParse = ([airline, airlineId, srcIata, srcAirportId, dstIata, dstAirportId, codeshare, stops, equipment]) => ({ airline, airlineId, srcIata, srcAirportId, dstIata, dstAirportId, codeshare, stops, equipment});

const AirlineGlobe = () => {
  const globeEl = React.useRef();
  const [airports, setAirports] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    Promise.all([
      fetch('https://raw.githubusercontent.com/jpatokal/openflights/master/data/airports.dat').then(res => res.text())
        .then(d => d3.csvParseRows(d, airportParse)),
      fetch('https://raw.githubusercontent.com/jpatokal/openflights/master/data/routes.dat').then(res => res.text())
        .then(d => d3.csvParseRows(d, routeParse))
    ]).then(([airports, routes]) => {

      const byIata = indexBy(airports, 'iata', false);

      const filteredRoutes = routes
        .filter(d => byIata.hasOwnProperty(d.srcIata) && byIata.hasOwnProperty(d.dstIata)) 
        .filter(d => d.stops === '0')
        .map(d => Object.assign(d, {
          srcAirport: byIata[d.srcIata],
          dstAirport: byIata[d.dstIata]
        }))
        .filter(d => d.srcAirport.country === COUNTRY && d.dstAirport.country !== COUNTRY); 

      setAirports(airports);
      setRoutes(filteredRoutes);
    });
  }, []);

  React.useEffect(() => {
    globeEl.current.pointOfView({ lat: 39.6, lng: -98.5, altitude: 2 });
  }, []);

  React.useEffect(() => {
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.4;
  }, []);

  React.useEffect(() => {
    if (globeEl.current) {
      globeEl.current.controls().enableZoom = false;
      globeEl.current.controls().enablePan = false;
 
    }
  }, []);

  return (
    <div className="globe-container" style={{ width: '50%', height: '50%'}}>
    <Globe
      ref={globeEl}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      backgroundColor="rgba(0, 0, 0, 0)"

      arcsData={routes}
      arcStartLat={d => +d.srcAirport.lat}
      arcStartLng={d => +d.srcAirport.lng}
      arcEndLat={d => +d.dstAirport.lat}
      arcEndLng={d => +d.dstAirport.lng}
      arcDashLength={0.25}
      arcDashGap={1}
      arcDashInitialGap={() => Math.random()}
      arcDashAnimateTime={4000}
      arcColor={d => [`rgba(120, 79, 166, ${OPACITY})`, `rgba(47, 86, 166, ${OPACITY})`]}
      arcsTransitionDuration={0}

      pointsData={airports}
      pointColor={() => 'orange'}
      pointAltitude={0}
      pointRadius={0.02}
      pointsMerge={true}
    />
    <div className="interaction-mask"></div>
    </div>
  );

};

export default AirlineGlobe;
