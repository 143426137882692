import React, { useEffect, useState } from "react";
import darkearth from "../../assets/images/dark-earth.jpg";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "../../assets/styles/customStyles.css";
import { gsap } from "gsap";

const ContactForm = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 786);
  const [isMonitor, setIsMonitor] = React.useState(window.innerWidth > 1800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 786);
      setIsMonitor(window.innerWidth > 1800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
      const button = document.querySelector(".button");

      if (button) {
        const getVar = (variable) =>
          getComputedStyle(button).getPropertyValue(variable);

        const handleClick = (e) => {
          if (!button.classList.contains("active")) {
            button.classList.add("active");

            gsap.to(button, {
              keyframes: [
                {
                  "--left-wing-first-x": 50,
                  "--left-wing-first-y": 100,
                  "--right-wing-second-x": 50,
                  "--right-wing-second-y": 100,
                  duration: 0.2,
                  onComplete() {
                    gsap.set(button, {
                      "--left-wing-first-y": 0,
                      "--left-wing-second-x": 40,
                      "--left-wing-second-y": 100,
                      "--left-wing-third-x": 0,
                      "--left-wing-third-y": 100,
                      "--left-body-third-x": 40,
                      "--right-wing-first-x": 50,
                      "--right-wing-first-y": 0,
                      "--right-wing-second-x": 60,
                      "--right-wing-second-y": 100,
                      "--right-wing-third-x": 100,
                      "--right-wing-third-y": 100,
                      "--right-body-third-x": 60,
                    });
                  },
                },
                {
                  "--left-wing-third-x": 20,
                  "--left-wing-third-y": 90,
                  "--left-wing-second-y": 90,
                  "--left-body-third-y": 90,
                  "--right-wing-third-x": 80,
                  "--right-wing-third-y": 90,
                  "--right-body-third-y": 90,
                  "--right-wing-second-y": 90,
                  duration: 0.2,
                },
                {
                  "--rotate": 50,
                  "--left-wing-third-x": 27,
                  "--left-wing-third-y": 95,
                  "--right-body-third-x": 45,
                  "--right-wing-second-x": 45,
                  "--right-wing-third-x": 60,
                  "--right-wing-third-y": 83,
                  duration: 0.25,
                },
                {
                  "--rotate": 55,
                  "--plane-x": -8,
                  "--plane-y": 24,
                  duration: 0.2,
                },
                {
                  "--rotate": 40,
                  "--plane-x": 45,
                  "--plane-y": -180,
                  "--plane-opacity": 0,
                  duration: 0.3,
                  onComplete() {
                    setTimeout(() => {
                      button.removeAttribute("style");
                      gsap.fromTo(
                        button,
                        {
                          opacity: 0,
                          y: -8,
                        },
                        {
                          opacity: 1,
                          y: 0,
                          clearProps: true,
                          duration: 0.3,
                          onComplete() {
                            button.classList.remove("active");
                          },
                        }
                      );
                    }, 2000);
                  },
                },
              ],
            });

            gsap.to(button, {
              keyframes: [
                {
                  "--text-opacity": 0,
                  "--border-radius": 0,
                  "--left-wing-background": getVar("--primary-darkest"),
                  "--right-wing-background": getVar("--primary-darkest"),
                  duration: 0.1,
                },
                {
                  "--left-wing-background": getVar("--primary"),
                  "--right-wing-background": getVar("--primary"),
                  duration: 0.1,
                },
                {
                  "--left-wing-background": getVar("--primary-dark"),
                  "--right-wing-background": getVar("--primary-dark"),
                  duration: 0.4,
                },
                {
                  "--success-opacity": 1,
                  "--success-scale": 1,
                  duration: 0.25,
                  delay: 0.25,
                },
              ],
            });
          }
        };

        button.addEventListener("click", handleClick);

        return () => {
          button.removeEventListener("click", handleClick);
        };
      }
    });


  if (isMonitor) {
    // monitor styling
    const button = document.querySelector(".button");
    if (button) {
      const getVar = (variable) =>
        getComputedStyle(button).getPropertyValue(variable);

      button.addEventListener("click", (e) => {
        if (!button.classList.contains("active")) {
          button.classList.add("active");
        }
      });
    }
    return (
      <div
        className="bg-[#090c17] flex flex-col items-center w-full overflow-hidden "
        style={{ height: "100vh" }}
      >
        <div
          className="absolute"
          style={{
            width: "30vw",
            height: "30vw",
            top: "3vh",
            left: "20vw",
            borderRadius: "50%",
            backdropFilter: "blur(8vw)",
            background:
              "radial-gradient(closest-side, rgb(120,79,166) 0%, transparent 100%)",
            opacity: "0.4",
          }}
        />

        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/products">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4">
              products
            </a>
            <a href="/contact" className="px-4 text-purple-500">
              contact
            </a>
          </nav>
        </header>

        <div className="z-10 mt-10 mb-5 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500">
            Get in touch
          </h2>
          <p className="text-xl font-medium font-[PlusJakartaSans] text-gray-400 mt-2">
            building bridges to new horizons.
          </p>
        </div>

        <form
          action="https://formspree.io/f/xvoevyrz"
          method="POST"
          className="w-full max-w-6xl mx-auto p-5 bg-[black] rounded-3xl shadow-2xl z-10 min-h-min"
        >
          <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10 p-10 rounded-3xl">
            <div className="lg:w-1/2 text-white space-y-5">
              <h3 className="text-3xl font-bold">let's connect.</h3>
              <p className="text-lg font-light opacity-80">
                reach out if you're interested in our products.
              </p>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="first name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="last name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="email*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                  required
                />
                <input
                  type="text"
                  name="organization"
                  placeholder="organization"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                />
                <textarea
                  name="message"
                  placeholder="message*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full h-32"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="button relative text-white bg-gradient-to-br from-purple-600 to-blue-700 px-5 py-2 rounded shadow hover:shadow-lg transition duration-300 ease-in-out flex items-center justify-center"
              >
                <span className="default">let's go. 🚀</span>
                <span className="success">sent!</span>
                <div className="left"></div>
                <div className="right"></div>
              </button>
            </div>
            <div className="flex-1">
              <img
                src={darkearth}
                alt="Earth"
                className="rounded-3xl shadow-xl"
              />
            </div>
          </div>
        </form>
      </div>
    );
  } else if (!isMobile && !isMonitor) {
    // laptop styling
    const button = document.querySelector(".button");
    if (button) {
      const getVar = (variable) =>
        getComputedStyle(button).getPropertyValue(variable);

      button.addEventListener("click", (e) => {
        if (!button.classList.contains("active")) {
          button.classList.add("active");
        }
      });
    }
    return (
      <div
        className="bg-[#090c17] min-h-screen flex flex-col items-center w-full overflow-hidden"
        style={{ height: "810px" }}
      >
        <div
          className="absolute"
          style={{
            width: "30vw",
            height: "30vw",
            top: "5vh",
            left: "8vw",
            borderRadius: "50%",
            backdropFilter: "blur(8vw)",
            background:
              "radial-gradient(closest-side, rgb(120,79,166) 0%, transparent 100%)",
            opacity: "0.4",
          }}
        />

        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4">
              products
            </a>
            <a href="/contact" className="px-4 text-purple-500">
              contact
            </a>
          </nav>
        </header>

        <div className="z-10 mt-0 mb-5 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500">
            Get in touch
          </h2>
          <p className="text-xl font-medium  font-[PlusJakartaSans] text-gray-400 mt-2">
            building bridges to new horizons.
          </p>
        </div>

        <form
          action="https://formspree.io/f/xvoevyrz"
          method="POST"
          className="w-full max-w-5xl mx-auto p-5 bg-[black] rounded-3xl shadow-2xl z-10 min-h-min"
        >
          <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10 p-10 rounded-3xl">
            <div className="lg:w-1/2 text-white space-y-5">
              <h3 className="text-3xl font-bold">let's connect.</h3>
              <p className="text-lg font-light opacity-80">
                reach out if you're interested in our products.
              </p>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="first name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="last name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="email*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                  required
                />
                <input
                  type="text"
                  name="organization"
                  placeholder="organization"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                />
                <textarea
                  name="message"
                  placeholder="message*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full h-32"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="button relative text-white bg-gradient-to-br from-purple-600 to-blue-700 px-5 py-2 rounded shadow hover:shadow-lg transition duration-300 ease-in-out flex items-center justify-center"
              >
                <span className="default">let's go. 🚀</span>
                <span className="success">sent!</span>
                <div className="left"></div>
                <div className="right"></div>
              </button>
            </div>
            <div className="flex-1">
              <img
                src={darkearth}
                alt="Earth"
                className="rounded-3xl shadow-xl"
              />
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    //WIP MOBILE STYLING
    const button = document.querySelector(".button");
    if (button) {
      const getVar = (variable) =>
        getComputedStyle(button).getPropertyValue(variable);

      button.addEventListener("click", (e) => {
        if (!button.classList.contains("active")) {
          button.classList.add("active");
        }
      });
    }
    return (
      <div className="bg-[#090c17] flex flex-col items-center w-full min-h-screen max-h-screen overflow-hidden ">
        <div
          className="absolute"
          style={{
            width: "30vw",
            height: "30vw",
            top: "5vh",
            left: "8vw",
            borderRadius: "50%",
            backdropFilter: "blur(8vw)",
            background:
              "radial-gradient(closest-side, rgb(120,79,166) 0%, transparent 100%)",
            opacity: "0.4",
          }}
        />

        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4">
              products
            </a>
            <a href="/contact" className="px-4 text-purple-500">
              contact
            </a>
          </nav>
        </header>

        <div className="z-10 mt-5 mb-5 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-500">
            Get in touch
          </h2>
          <p className="text-xl font-medium font-[PlusJakartaSans] text-gray-400 mt-2">
            building bridges to new horizons.
          </p>
        </div>

        <form
          action="https://formspree.io/f/xvoevyrz"
          method="POST"
          className="w-full max-w-5xl mx-auto p-5 bg-[black] rounded-3xl shadow-2xl z-10 min-h-min"
        >
          <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10 p-10 rounded-3xl">
            <div className="lg:w-1/2 text-white space-y-5">
              <h3 className="text-3xl font-bold">let's connect.</h3>
              <p className="text-lg font-light opacity-80">
                reach out if you're interested in our products.
              </p>
              <div className="space-y-4">
                <div className="flex gap-4">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="first name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="last name*"
                    className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="email*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                  required
                />
                <input
                  type="text"
                  name="organization"
                  placeholder="organization"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full"
                />
                <textarea
                  name="message"
                  placeholder="message*"
                  className="font-[PlusJakartaSans] bg-transparent border border-gray-600 p-2 rounded text-white w-full h-32"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="relative text-white bg-gradient-to-br from-purple-600 to-blue-700 px-5 py-2 rounded shadow hover:shadow-lg transition duration-300 ease-in-out flex items-center justify-center"
              
              >
                <span className="default">let's go. 🚀</span>
                <span className="success">sent!</span>
                <div className="left"></div>
                <div className="right"></div>
              </button>
            </div>
            <div className="flex-1">
              <img
                src={darkearth}
                alt="Earth"
                className="rounded-3xl shadow-xl"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
};
export default ContactForm;
