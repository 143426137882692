import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import map from "../../assets/images/mapclipart.png";
import worldmap from "../../assets/images/worldmapclipart.png";
import ambulance from "../../assets/images/ambulanceclipart.png";

const Products = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 786);
  const [isMonitor, setIsMonitor] = React.useState(window.innerWidth > 1800);
  const [isVisible, setIsVisible] = React.useState(true);
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'auto' });
  };
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    const handleScroll = () => {
      const element = document.getElementById('elid');
      if (document.getElementById('elid') !== null) {
        const elementPos = element.getBoundingClientRect();

        if (elementPos.bottom < 0 || elementPos.top > window.innerHeight) {
          setIsVisible(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isMonitor) {
    // monitor styling
    return (
      <div className="bg-[#090c17] min-h-screen flex flex-col items-center w-full overflow-hidden">
        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4 text-purple-500">
              products
            </a>
            <a href="/contact" className="px-4">
              contact
            </a>
          </nav>
        </header>

        <button
          onClick={handleClick}
          className="relative w-50% max-w-xs bg-[#202020] rounded-full overflow-hidden my-4"
          data-aos="zoom-in-down">
          {isVisible && (
            <div id="elid" className="flex items-center justify-center h-full px-4">
              <div className="text-[#865bb5] font-bold text-sm mr-2 font-[PlusJakartaSans]">
                NEW
              </div>
              <div className="text-[#e0e0e0] font-normal text-base font-[PlusJakartaSans]">
                ✨ Geochron
              </div>
            </div>
          )}
        </button>

        <div className="z-10 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-white to-purple-500">
            Our Products
          </h2>
          <p className="text-xl font-medium font-[PlusJakartaSans] text-gray-400 mt-2">
            innovation in every direction.
          </p>
        </div>
        <div className="relative w-full max-w-md h-auto">
          <img className="w-full h-auto object-cover" alt="map" src={map} />
        </div>
        <div className="relative max-w-md mx-auto">
          <h2 className="font-[Lexend] font-bold text-white text-4xl text-center leading-tight">
            What is Mercator?
          </h2>
        </div>
        <div className="relative max-w-lg mx-auto mt-4">
          <p className="font-[PlusJakartaSans] text-base text-center leading-relaxed text-gray-400">
            At its core, Mercator is a young mapping and technology startup
            focused on providing geospatial services to a variety of clients. We
            utilize complex mathematical and computational concepts to build
            revolutionary products. Scroll down to learn more about what we’re
            building!
          </p>
        </div>
        <div ref={ref} className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="World map"
              src={worldmap}
            />
          </div>

          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Geochron
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              With its revolutionary method of considering the Coriolis effect,
              Geochron is capable of providing both commercial planes and
              airliners with more efficient routes around the globe. Geochron
              utilizes a new formula for map projections, capable of taking
              account the full capacity of the Coriolis effect. Using this
              formula, the projections are capable of producing paths of least
              time across the Earth, which we at Mercator call geochronics.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Coming soon!
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              Using graph theory and deep learning to optimize emergency route
              planning.
            </p>
          </div>
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="Ambulance"
              src={ambulance}
            />
          </div>
        </div>
      </div>
    );
  } else if (!isMobile && !isMonitor) {
    // laptop styling
    return (
      <div className="bg-[#090c17] min-h-screen flex flex-col items-center w-full overflow-hidden">
        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4 text-purple-500">
              products
            </a>
            <a href="/contact" className="px-4">
              contact
            </a>
          </nav>
        </header>

        <button
          onClick={handleClick}
          className="relative w-50% max-w-xs bg-[#202020] rounded-full overflow-hidden my-4"
          data-aos="zoom-in-down">
          {isVisible && (
            <div id="elid" className="flex items-center justify-center h-full px-4">
              <div className="text-[#865bb5] font-bold text-sm mr-2 font-[PlusJakartaSans]">
                NEW
              </div>
              <div className="text-[#e0e0e0] font-normal text-base font-[PlusJakartaSans]">
                ✨ Geochron
              </div>
            </div>
          )}
        </button>

        <div className="z-10 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-white to-purple-500">
            Our Products
          </h2>
          <p className="text-xl font-medium font-[PlusJakartaSans] text-gray-400 mt-2">
            innovation in every direction.
          </p>
        </div>
        <div className="relative w-full max-w-md h-auto">
          <img className="w-full h-auto object-cover" alt="map" src={map} />
        </div>
        <div className="relative max-w-md mx-auto">
          <h2 className="font-[Lexend] font-bold text-white text-4xl text-center leading-tight">
            What is Mercator?
          </h2>
        </div>
        <div className="relative max-w-lg mx-auto mt-4">
          <p className="font-[PlusJakartaSans] text-base text-center leading-relaxed text-gray-400">
            At its core, Mercator is a young mapping and technology startup
            focused on providing geospatial services to a variety of clients. We
            utilize complex mathematical and computational concepts to build
            revolutionary products. Scroll down to learn more about what we’re
            building!
          </p>
        </div>
        <div ref={ref} className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="World map"
              src={worldmap}
            />
          </div>

          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Geochron
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              With its revolutionary method of considering the Coriolis effect,
              Geochron is capable of providing both commercial planes and
              airliners with more efficient routes around the globe. Geochron
              utilizes a new formula for map projections, capable of taking
              account the full capacity of the Coriolis effect. Using this
              formula, the projections are capable of producing paths of least
              time across the Earth, which we at Mercator call geochronics.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Coming soon!
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              Using graph theory and deep learning to optimize emergency route
              planning.
            </p>
          </div>
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="Ambulance"
              src={ambulance}
            />
          </div>
        </div>
      </div>
    );
  } else {
    //WIP MOBILE STYLING
    return (
      <div className="bg-[#090c17] min-h-screen flex flex-col items-center w-full overflow-hidden">
        <header className="w-full p-5 flex justify-between items-center z-10">
          <Link to="/">
            <img src={logo} alt="Logo" className="w-14 h-auto" />
          </Link>
          <nav className="text-white font-[PlusJakartaSans]">
            <a href="/products" className="px-4 text-purple-500">
              products
            </a>
            <a href="/contact" className="px-4">
              contact
            </a>
          </nav>
        </header>

        <button
          onClick={handleClick}
          className="relative w-50% max-w-xs bg-[#202020] rounded-full overflow-hidden my-4"
          data-aos="zoom-in-down">
          {isVisible && (
            <div id="elid" className="flex items-center justify-center h-full px-4">
              <div className="text-[#865bb5] font-bold text-sm mr-2 font-[PlusJakartaSans]">
                NEW
              </div>
              <div className="text-[#e0e0e0] font-normal text-base font-[PlusJakartaSans]">
                ✨ Geochron
              </div>
            </div>
          )}
        </button>

        <div className="z-10 text-center">
          <h2 className="text-5xl font-bold font-[Lexend] text-transparent bg-clip-text bg-gradient-to-r from-white to-purple-500">
            Our Products
          </h2>
          <p className="text-xl font-medium font-[PlusJakartaSans] text-gray-400 mt-2">
            innovation in every direction.
          </p>
        </div>
        <div className="relative w-full max-w-md h-auto">
          <img className="w-full h-auto object-cover" alt="map" src={map} />
        </div>
        <div className="relative max-w-md mx-auto">
          <h2 className="font-[Lexend] font-bold text-white text-4xl text-center leading-tight">
            What is Mercator?
          </h2>
        </div>
        <div className="relative max-w-lg mx-auto mt-4">
          <p className="font-[PlusJakartaSans] text-base text-center leading-relaxed text-gray-400">
            At its core, Mercator is a young mapping and technology startup
            focused on providing geospatial services to a variety of clients. We
            utilize complex mathematical and computational concepts to build
            revolutionary products. Scroll down to learn more about what we’re
            building!
          </p>
        </div>
        <div ref={ref} className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="World map"
              src={worldmap}
            />
          </div>

          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Geochron
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              With its revolutionary method of considering the Coriolis effect,
              Geochron is capable of providing both commercial planes and
              airliners with more efficient routes around the globe. Geochron
              utilizes a new formula for map projections, capable of taking
              account the full capacity of the Coriolis effect. Using this
              formula, the projections are capable of producing paths of least
              time across the Earth, which we at Mercator call geochronics.
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center w-full my-4">
          <div className="w-1/2 text-center px-4">
            <h2 className="font-[Lexend] font-bold text-white text-4xl leading-tight">
              Coming soon!
            </h2>
            <p className="font-[PlusJakartaSans] text-base leading-relaxed text-gray-400">
              Using graph theory and deep learning to optimize emergency route
              planning.
            </p>
          </div>
          <div className="w-1/2">
            <img
              className="w-full h-auto object-cover"
              alt="Ambulance"
              src={ambulance}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Products;
