import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/Home/LandingPage";
import "./App.css";
import { Link } from "react-router-dom";
import logoImage from "./assets/images/logo.png";
import Contact from "./components/Contact/Contact";
import Products from "./components/Products/Products";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/products" element={<Products/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;